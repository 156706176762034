<template>
  <v-row v-if="productList && productList.length" class="promo-list-grid">
    <v-col
      v-for="product in productList"
      :key="product.productId"
      cols="12"
      sm="6"
      md="6"
      lg="4"
      xl="3"
      class="product-col"
    >
      <PromoCard
        :product="product"
        @addPersonalPromoItem="addPersonalPromoItem"
        :disabled="checkSelected(product.productId)"
      />
    </v-col>
  </v-row>
</template>
<style lang="scss">
.promo-list-grid {
  .promo-card {
    height: 100%;
    .product {
      height: 100%;
    }
    .card-content {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
<script>
import PromoCard from "@/components/personalPromo/PromoCard.vue";

export default {
  name: "PromoListGrid",
  components: {
    PromoCard
    // ProductCard
  },
  props: {
    category: { type: Object, required: true },
    productList: { type: Array, required: true },
    selectedItems: { type: Array, required: true }
  },
  data() {
    return {
      sortFilter: undefined,
      categoryFilter: undefined,
      busy: false,
      key: 0,
      loading: true
    };
  },
  methods: {
    addPersonalPromoItem(product) {
      this.$emit("addPersonalPromoItem", product);
    },
    /*async fetcCustomOfferProducts(isAddMore = false) {
      let _this = this;
      this.loading = true;
      let res = await customService.customPromoProducts({
        store_id: this.category?.store.storeId,
        q: this.wordSearched,
        page: this.page,
        page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
        sort: this.sortFilter
      });
      if (res && res.products) {
        if (isAddMore) {
          let prodList = this.productList.concat(res.products);
          _this.productList = prodList;
          console.log("this.productList", this.productList);
        } else {
          this.productList = res.products;
        }
        // this.productList = res.products;
        this.pager = res.page;
      } else {
        this.productList = [];
      }
      this.key++;
      this.loading = false;
    },*/

    sortFilterChanged(newSortFilter) {
      this.sortFilter = newSortFilter;
      this.fetcCustomOfferProducts();
    },
    categoryChanged(newCategoryFilter) {
      this.categoryFilter = newCategoryFilter;
      this.fetcCustomOfferProducts();
    },
    wordChanged(wordSearched) {
      this.wordSearched = wordSearched;
      this.fetcCustomOfferProducts();
    },
    handlePageFilter(newPage) {
      var _this = this;
      this.page = newPage;
      console.log("page -> ", this.page);
      this.fetcCustomOfferProducts();
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    },
    checkSelected(id) {
      return this.selectedItems.some(item => item.productId === id);
    }
  }
};
</script>
