var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ProductCard',{staticClass:"promo-card",class:{ 'disabled-card': _vm.disabled },attrs:{"product":_vm.product,"linkComponentName":'',"createOfferService":_vm.createOfferService},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-card-text',{staticClass:"py-0 px-0"},[(_vm.product.warehousePromo)?_c('span',{staticClass:"text-uppercase rounded-sm py-1 px-3 promo1 white--text font-weight-bold mb-md-2 text-no-wrap promo-header",domProps:{"innerHTML":_vm._s(_vm.product.warehousePromo?.view?.header)}}):_vm._e(),_c('br'),(_vm.product.warehousePromo?.view?.footer)?_c('span',{staticClass:"text-uppercase rounded-sm py-1 px-3 primary white--text font-weight-bold text-no-wrap promo-footer",domProps:{"innerHTML":_vm._s(_vm.product.warehousePromo?.view?.footer)}}):_vm._e()]),_c('v-card-text',{staticClass:"d-flex align-end mt-auto px-0"},[(
          _vm.createOfferService == 'online' &&
            (_vm.product.warehousePromo?.view?.bubble ||
              _vm.product.warehousePromo?.view?.body)
        )?_c('div',{staticClass:"d-flex flex-column mr-auto"},[_c('span',{staticClass:"text-decoration-line-through",domProps:{"innerHTML":_vm._s(_vm.product.warehousePromo?.view?.bubble)}}),_c('span',{staticClass:"font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.product.warehousePromo?.view?.body)}})]):_vm._e(),_c('span',[_c('small',[_vm._v(_vm._s(_vm.$t("createYourOffer.promoMaxQuantity")))])])]),_c('v-card-actions',{staticClass:"px-0"},[_c('v-btn',{staticClass:"rounded-sm w-100 elevation-0",attrs:{"color":"primary","large":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.addPersonalPromoItem(_vm.product)}}},[_vm._v(_vm._s(_vm.$t("createYourOffer.cta.addToCart")))])],1)]},proxy:true}])},[_vm._t("productdescr",function(){return [(
        _vm.product.productInfos.TIPOLOGIA == 'Pezzo' && _vm.product.priceUmDisplay
      )?_c('span',{staticClass:"descr"},[_c('span',{staticClass:"product-package font-weight-bold"},[_vm._v(_vm._s(_vm.product.productInfos.PACKAGE_DESCR ? _vm.product.productInfos.PACKAGE_DESCR : _vm.product.description)+" ")]),_c('span',{staticClass:"weight-unit"},[_vm._v("al "+_vm._s(_vm.product.weightUnitDisplay)+" "+_vm._s(_vm.$n(_vm.product.priceUmDisplay, "currency")))])]):(_vm.product.productInfos.TIPOLOGIA == 'Confezione')?_c('span',[_c('span',{staticClass:"weight-unit",attrs:{"ng-if":"product.priceUmDisplay"}},[_vm._v("al "+_vm._s(_vm.product.weightUnitDisplay)+" "+_vm._s(_vm.$n(_vm.product.priceUmDisplay, "currency")))])]):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }