<template>
  <div class="create-offer flex-column">
    <div class="d-flex flex-column flex-md-row">
      <CategoryTitle :category="category" />
    </div>
    <h5
      class="my-3"
      v-if="selectedWarehouseStore && selectedWarehouseStore.expirationDate"
    >
      {{
        `${$t("createYourOffer.warehouse.expirationDate")}: ${$dayjs(
          selectedWarehouseStore.expirationDate
        ).format("DD/MM/YYYY")}`
      }}
    </h5>
    <v-row>
      <v-col
        class="create-offer-selection"
        cols="12"
        md="8"
        order="2"
        order-md="1"
      >
        <!--CREATE YOUR OFFER SELECTION TYPE-->
        <v-btn-toggle
          class="grey lighten-3 d-flex flex-column flex-lg-row"
          v-model="parentLeafleTypeId"
          dense
          mandatory
          @change="changeParentLeafletType"
        >
          <v-btn
            class="mb-3 mb-lg-0 accent btn-service btn-service-online rounded-md"
            color="accent"
            :value="2"
            height="73"
          >
            <span
              class="font-weight-regular default--text"
              v-html="$t('createYourOffer.createOfferOnline')"
            ></span>
            <v-img
              class="logo_aliperme"
              max-height="50"
              contain
              src="/logo/aliperme_positivo.svg"
            ></v-img>
            <!-- </div> -->
          </v-btn>
          <v-btn
            :value="1"
            color="accent"
            class="accent btn-service btn-service-store rounded-md"
            height="73"
          >
            <div
              class="btn-service-store-content d-flex flex-column align-start"
            >
              <span
                class="font-weight-regular default--text"
                v-html="$t('createYourOffer.createOfferInStore')"
              ></span>
              <span
                v-if="selectedWarehouseStore"
                class=" default--text warehouse-name text-"
              >
                {{ selectedWarehouseStore.name }}
              </span>
            </div>
            <v-btn
              class="btn-change-address"
              text
              :value="1"
              @click.stop.prevent="dialog = true"
            >
              <v-icon x-small color="default">$edit</v-icon></v-btn
            >
          </v-btn>
        </v-btn-toggle>

        <!-- DIALOG -->
        <v-dialog v-model="dialog" width="500">
          <v-card class="d-flex flex-column address-modal">
            <v-icon class="ml-auto mb-5" v-on:click="dialog = false">
              icon-close
            </v-icon>
            <legend class="font-weight-bold mb-5">
              {{ $t("createYourOffer.warehouse.title") }}
            </legend>
            <v-autocomplete
              :placeholder="$t('createYourOffer.warehouse.title')"
              v-model="autocompleteWarehouseModel"
              return-object
              :items="warehouses"
              item-text="name"
              outlined
              dense
            ></v-autocomplete>
            <v-card-actions class="pr-0">
              <v-btn
                color="primary"
                class="ml-auto"
                @click="selectWarehouse(autocompleteWarehouseModel)"
                >{{ $t("createYourOffer.cta.choose") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- DIALOG -->

        <!--FILTERS-->
        <div class="awards-filter my-6" v-if="productList">
          <AwardsFilter
            :totalItems="totItems"
            :productList="productList"
            :facets="facets"
            :facetsFilter="facetsFilter"
            :sortFilter="sortFilter"
            :hideFilterString="hideFilterString"
            :hideSortFilter="hideSortFilter"
            :filteredProductsCount="filteredProductsCount"
            :awardsContainer="false"
            :createYourOfferContainer="true"
            @handleFacetFilter="handleFacetFilter"
            @handleFacetFilterMobile="handleFacetFilterMobile"
            @handleFreeSearch="handleFreeSearch"
            @handleSortFilter="handleSortFilter"
          />
        </div>
        <!--CREATE YOUR OFFER PRODUCTS LIST-->
        <template v-if="totItems > 0">
          <PromoListGrid
            :category="category"
            :productList="productList"
            :selectedItems="promoItems"
            v-if="productList && productList.length"
            @addPersonalPromoItem="addPersonalPromoItem"
          />

          <v-pagination
            v-if="pager && pager.totItems > pager.itemsPerPage"
            :value="pageFilter"
            :page="pager.selPage"
            :length="Math.ceil(pager.totItems / pager.itemsPerPage)"
            @next.stop.prevent="handlePageFilter"
            @prev="handlePageFilter"
            @input="handlePageFilter"
            class="py-6"
            prev-icon="$chevronLeft"
            next-icon="$chevronRight"
            color="primary"
          ></v-pagination>
        </template>
        <div v-else-if="loading" class="product-list-grid-skeleton mt-4">
          <v-row>
            <v-col
              v-for="index in 12"
              :key="index"
              cols="12"
              sm="6"
              md="6"
              lg="3"
              xl="4"
            >
              <v-skeleton-loader
                type="image, list-item-three-line"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </div>
        <span v-else>{{ $t("awards.products.noProducts") }}</span>
      </v-col>
      <v-col
        cols="12"
        md="4"
        style="height: fit-content;"
        order="1"
        order-md="2"
      >
        <div class="accent rounded-md create-offer-list pa-2">
          <!--PERSONAL PROMO OFFERS-->
          <h2>{{ $t("createYourOffer.selectedProducts") }}</h2>
          <div v-if="promoItems">
            <CreateOfferCard
              v-for="promoItem in promoItems"
              :key="promoItem.productId"
              :product="promoItem"
              @removePersonalPromoItem="removePersonalPromoItem"
            />

            <v-btn
              large
              color="primary"
              class="w-100"
              depressed
              @click="printOffer()"
              >{{ $t("createYourOffer.cta.printProducts") }}</v-btn
            >
          </div>
          <p v-else>{{ $t("createYourOffer.noPromoItem") }}</p>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<style lang="scss">
@media screen and (max-width: 374px) {
  .btn-service {
    .v-btn__content {
      display: flex;
      flex-direction: column;
    }
  }
}
@media screen and (min-width: 1264px) {
  .v-btn-toggle {
    gap: 20px;
    .btn-service {
      flex: 1 1 0;
    }
  }
}
.v-item-group.v-btn-toggle .v-btn.btn-service.v-item--active.v-btn--active {
  border: 2px solid var(--v-primary-lighten1) !important;
}

.v-btn-toggle > .v-btn.v-btn--active {
  color: var(--v-accent-base) !important;
}
.btn-service {
  background-color: var(--v-accent-base) !important;
  &-store {
    .warehouse-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 300px;
      max-width: 300px;
      text-align: left;
    }
  }

  .btn-change-address.v-btn--active::before {
    opacity: 0;
  }
  .v-btn__content {
    justify-content: space-between;
  }
}
.is-cordova {
  .address-modal {
    padding: 10px;
    input::placeholder {
      font-size: 11px;
    }
  }
}
.awards-filter {
  .v-text-field--outlined {
    background-color: $white;
  }
}
/*.create-offer {
  div.accent {
    flex: 1 1 0;
    .v-btn--round {
      border-radius: 0;
    }
    .v-btn:focus {
      &::before {
        background-color: transparent !important;
      }
    }
    .warehouse-name {
      text-overflow: ellipsis;
    }
  }
  &-filter {
    .v-input__control {
      display: flex;
      align-items: center;
      .v-input__append-inner {
        margin-top: 0 !important;
        align-self: center;
      }
    }
  }
  .product-choosed {
    .promo-item-selected-img {
      width: 70px;
      height: 70px;
    }
  }
  .btn-service-store {
    .v-btn__content {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .btn-change-address {
      .v-btn__content {
        justify-content: center;
      }
    }
  }
  .v-item-group.v-btn-toggle {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: transparent;
    .v-btn.btn-service {
      // .btn-service-online
      // .btn-service-store
      width: 49%;
      background: var(--v-grey-base);
      color: black;
      border: 2px solid var(--v-accent-base) !important;
      &.v-item--active.v-btn--active {
        border: 2px solid var(--v-primary-lighten1) !important;
        &::before {
          opacity: 0;
        }
      }
    }
    .v-btn.btn-change-address {
      background-color: transparent;
    }
  }
}*/
@media print {
  .create-offer-selection {
    display: none;
  }
  .create-offer-list {
    .v-btn {
      display: none;
    }
    .promo-item-selected {
      border: 1px solid var(--v-default-base);
    }
  }
}
.logo_aliperme {
  .v-responsive__content {
    width: 100% !important;
    @media (max-width: 374px) {
      width: 300px !important;
    }
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import AwardsFilter from "@/components/awards/AwardsFilter.vue";
import PromoListGrid from "@/components/personalPromo/PromoListGrid.vue";
import CreateOfferCard from "@/components/product/createOffert/CreateOfferCard.vue";

import categoryMixins from "~/mixins/category";

import leafletService from "~/service/leafletService";
import customService from "@/service/customService";
import RegistrationService from "~/service/userService";
import addressService from "@/commons/service/addressService";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "CreateOffer",
  props: {
    hideFilterString: { type: String, required: false },
    hideSortFilter: { type: String, required: false }
  },
  components: {
    CategoryTitle,
    AwardsFilter,
    PromoListGrid,
    CreateOfferCard
  },
  mixins: [categoryMixins],
  data() {
    return {
      parentLeafleTypeId: 1,
      dialog: false,
      autocompleteWarehouseModel: null,
      selectedWarehouseOnline: null,
      selectedWarehouseStore: null,
      warehouses: null,
      promoItems: [],
      listData: null,
      productList: null,
      facets: [],
      wordSearched: null,
      searchText: null,
      filteredProductsCount: 0,
      loading: false,
      pager: {},
      page: 1,
      key: 0,
      user: null,
      products: [],
      createYourOfferContainer: true
    };
  },
  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter",
      createOfferWarehouse: "custom/getCreateOfferWarehouse",
      createOfferService: "custom/getCreateOfferService",
      storeId: "cart/getStoreId"
    }),

    totItems() {
      return this.pager && this.pager.totItems ? this.pager.totItems : 0;
    },
    leafletId() {
      if (this.parentLeafleTypeId == 1) {
        return this.selectedWarehouseStore.leafletId;
      } else {
        return this.selectedWarehouseOnline.leafletId;
      }
    },
    hasMoreItems() {
      if (this.pager) {
        return (
          this.pager.totItems / this.pager.itemsPerPage > this.pager.selPage
        );
      } else {
        return false;
      }
    },
    facetsFilter: {
      get() {
        return this.$store.state.category.filters.facets;
      },
      set() {
        // this.$store.commit("app/updatedDrawerLeft", value);
      }
    },
    sortFilter: {
      get() {
        return this.$store.state.category.filters.sort;
      },
      set() {
        // this.$store.commit("app/updatedDrawerLeft", value);
      }
    },
    isWishListItemPresent() {
      return this.listData && this.listData.cartItems.length > 0;
    }
  },
  methods: {
    ...mapActions({
      getCart: "cart/loadCart",
      setSelectedWarehouse: "custom/setCreateOfferWarehouse",
      setCreateOfferService: "custom/setCreateOfferService"
    }),
    async addPersonalPromoItem(product) {
      let _this = this;
      // check if item in not already added
      _this.promoItems.push(product);
      await customService.addPromoToList(_this.promoItems);
      this.personalPromoItems();
    },
    async removePersonalPromoItem(wpId) {
      // let filteredItemsMap = [];
      let productsPromo = [];

      this.promoItems.forEach(promoItem => {
        if (promoItem.warehousePromo.view.warehousePromoId != wpId) {
          productsPromo.push(promoItem);
          // filteredItemsMap.push({
          //   productId: promoItem.warehousePromo.view.warehousePromoId,
          //   quantity: 1
          // });
        }
      });
      // customService.addPromoToList(filteredItemsMap);
      await customService.addPromoToList(productsPromo);
      this.personalPromoItems();
    },
    async handleFreeSearch(searchText) {
      this.$store.dispatch("category/setFilterPage", 1);
      this.wordSearched = searchText;
      await this.fetcCustomOfferProducts();
    },
    async handleFacetFilter(filter) {
      this.$store.dispatch("category/setFilterFacets", {
        facets: filter.facets,
        parameterName: filter.parameterName
      });
      await this.fetcCustomOfferProducts();
    },
    async handleSortFilter(sort) {
      this.$store.dispatch("category/setFilterSort", sort);
      await this.fetcCustomOfferProducts();
    },
    async handleFacetFilterMobile(selectedFacetsXS) {
      this.$store.dispatch("category/setFilterFacetsMobile", {
        facets: selectedFacetsXS
      });
      await this.fetcCustomOfferProducts();
    },
    async changeParentLeafletType() {
      this.setCreateOfferService(
        this.parentLeafleTypeId == 1 ? "store" : "online"
      );
      await this.warehouseSearch();
      await this.$store.dispatch("category/setFilterPage", 1);
      this.selectWarehouse();
    },
    async fetcCustomOfferProducts(append) {
      let res = await customService.customPromoProducts(
        {
          //store_id: this.category?.store.storeId,
          //parent_category_id: this.category?.categoryId,

          parent_leaflet_id: this.leafletId,
          category_id: this.categoryId || null,
          q: this.wordSearched,
          page: this.pageFilter,
          page_size: process.env.VUE_APP_PRODUCT_LIST_PAGE_SIZE,
          sort: this.sortFilter
        },
        this.facetsFilter
      );
      if (append) {
        this.products = [...this.products, ...res.products];
      } else {
        this.products = res.products;
      }

      if (res && res.products) {
        this.productList = res.products;
      }
      this.key++;
      this.pager = res.page;

      this.facetsFilter;
      this.facets = res.facets;
      this.filteredProductsCount = res.page.totItems;
    },

    async personalPromoItems() {
      let _this = this;
      let result = await customService.getPromoList(this.leafletId);
      _this.promoItems = result.products;
    },
    async fetchUserData() {
      this.user = await RegistrationService.getUserDetail();
    },
    async warehouseSearch() {
      const result = await leafletService.search({
        parent_leaflet_type_id: this.parentLeafleTypeId
      });

      this.warehouses = result.map(wh => ({
        name: `${wh.name} - ${wh.descr}`,
        expirationDate: wh.validateDateTo,
        leafletId: wh.leafletId,
        title: wh.title
      }));
    },
    async selectWarehouse(warehouse) {
      this.$store.dispatch("category/setFilterPage", 1);
      this.loading = true;
      this.dialog = false;
      let findWarehouse = await addressService.findWarehouse();

      findWarehouse = findWarehouse.warehouses.filter(
        wh => this.user.defaultStoreAddress.addressId == wh.address.addressId
      );

      if (warehouse && warehouse != this.selectedWarehouseStore) {
        this.selectedWarehouseStore = warehouse;
        this.fetcCustomOfferProducts();
        this.personalPromoItems();
      } else {
        if (
          this.selectedWarehouseStore == null &&
          this.warehouses &&
          this.warehouses.length > 0 &&
          this.parentLeafleTypeId == 1
        ) {
          if (
            this.user.defaultStoreAddress &&
            this.user.defaultStoreAddress.addressId
          ) {
            const foundObject = this.warehouses.find(
              obj =>
                parseInt(obj.title) ===
                (findWarehouse[0].warehouseId == null
                  ? this.user.defaultStoreAddress.addressId
                  : findWarehouse[0].warehouseId)
            );

            if (foundObject) {
              this.selectedWarehouseStore = foundObject;
            } else {
              this.selectedWarehouseStore = this.warehouses[0];
            }
          }
        } else if (
          this.warehouses &&
          this.warehouses.length > 0 &&
          this.parentLeafleTypeId == 2
        ) {
          this.selectedWarehouseOnline = this.warehouses[0];
        }
        await this.setSelectedWarehouse(this.selectedWarehouse);
        await this.fetcCustomOfferProducts();
        this.personalPromoItems();

        this.loading = false;
      }
    },
    // async setWarehouse() {
    //   this.warehouseAddress = this.selectedWarehouse.name;
    //   this.warehouseDate = this.selectedWarehouse.expirationDate;

    //   await this.setSelectedWarehouse(this.selectedWarehouse);
    //   // await this.warehouseSearch();
    //   this.fetcCustomOfferProducts();
    //   this.personalPromoItems();

    //   //const cart = await this.getCart();
    //   //console.log(cart);

    //   this.selectWarehouse = this.createOfferWarehouse
    //     ? this.createOfferWarehouse
    //     : null;
    //   this.dialog = false;
    // },
    onIntersect(entries) {
      if (entries[0].isIntersecting) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMoreItems) {
        this.$store.dispatch("category/setFilterPage", this.pageFilter + 1);
        this.fetcCustomOfferProducts(true);
      }
    },
    handlePageFilter(page) {
      var _this = this;
      this.$store.dispatch("category/setFilterPage", page);
      //await di this.fetchProduts
      this.fetcCustomOfferProducts();
      //oppure mettere goTo(0) dentro timeout
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    },
    printOffer() {
      if (this.isCordova) {
        window.cordova.plugins.printer.print();
      } else {
        window.print();
      }
    }
  },
  async mounted() {
    this.loading = true;
    await this.fetchUserData();
    this.setCreateOfferService(
      this.parentLeafleTypeId == 1 ? "store" : "online"
    );
    await this.warehouseSearch();
    await this.selectWarehouse();
    this.loading = false;
  }
};
</script>
