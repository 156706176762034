<template>
  <!--Card is disabled if is not in the catalog section-->
  <ProductCard
    class="promo-card"
    :product="product"
    :linkComponentName="''"
    :class="{ 'disabled-card': disabled }"
    :createOfferService="createOfferService"
  >
    <template v-slot:actions>
      <v-card-text class="py-0 px-0">
        <span
          v-if="product.warehousePromo"
          class="text-uppercase rounded-sm py-1 px-3 promo1 white--text font-weight-bold mb-md-2 text-no-wrap promo-header"
          v-html="product.warehousePromo?.view?.header"
        ></span>
        <br />
        <span
          v-if="product.warehousePromo?.view?.footer"
          class="text-uppercase rounded-sm py-1 px-3 primary white--text font-weight-bold text-no-wrap promo-footer"
          v-html="product.warehousePromo?.view?.footer"
        ></span>
      </v-card-text>
      <v-card-text class="d-flex align-end mt-auto px-0">
        <div
          class="d-flex flex-column mr-auto"
          v-if="
            createOfferService == 'online' &&
              (product.warehousePromo?.view?.bubble ||
                product.warehousePromo?.view?.body)
          "
        >
          <!-- body and bubble has the price, show only if shop online -->
          <span
            class="text-decoration-line-through"
            v-html="product.warehousePromo?.view?.bubble"
          ></span>
          <span
            class="font-weight-bold"
            v-html="product.warehousePromo?.view?.body"
          ></span>
        </div>
        <span
          ><small>{{ $t("createYourOffer.promoMaxQuantity") }}</small></span
        >
      </v-card-text>
      <v-card-actions class="px-0">
        <v-btn
          color="primary"
          large
          @click="addPersonalPromoItem(product)"
          class="rounded-sm w-100 elevation-0"
          :disabled="disabled"
          >{{ $t("createYourOffer.cta.addToCart") }}</v-btn
        >
      </v-card-actions>
    </template>
    <slot name="productdescr">
      <span
        v-if="
          product.productInfos.TIPOLOGIA == 'Pezzo' && product.priceUmDisplay
        "
        class="descr"
      >
        <span class="product-package font-weight-bold"
          >{{
            product.productInfos.PACKAGE_DESCR
              ? product.productInfos.PACKAGE_DESCR
              : product.description
          }}
        </span>

        <span class="weight-unit"
          >al {{ product.weightUnitDisplay }}
          {{ $n(product.priceUmDisplay, "currency") }}</span
        ></span
      >
      <span v-else-if="product.productInfos.TIPOLOGIA == 'Confezione'"
        ><span class="weight-unit" ng-if="product.priceUmDisplay"
          >al {{ product.weightUnitDisplay }}
          {{ $n(product.priceUmDisplay, "currency") }}</span
        ></span
      >

      <!-- <span class="descr">
                <span class="font-weight-bold">{{ product.description }}</span>
                <span
                  class="weight-unit"
                  v-if="
                    product.productInfos &&
                      product.productInfos.TIPOLOGIA == 'Pezzo' &&
                      product.priceUmDisplay
                  "
                >
                  al {{ product.weightUnitDisplay }}
                  {{ $n(product.priceUmDisplay, "currency") }}
                </span>
              </span> -->
    </slot>
  </ProductCard>
</template>
<style lang="scss">
.promo-card {
  .description {
    height: min-content !important;
  }
  .cod-int {
    display: block !important;
  }
}
.promo-card.disabled-card {
  filter: grayscale(1);
}
.is-cordova {
  .promo-header,
  .promo-footer {
    width: fit-content;
    display: flex;
  }
  .promo-header {
    margin-bottom: 0px;
  }
}
</style>
<script>
import ProductCard from "@/components/product/ProductCard.vue";

import productMixin from "~/mixins/product";

import { mapGetters } from "vuex";

export default {
  name: "PromoCard",
  components: {
    ProductCard
  },
  props: {
    product: { type: Object, required: true },
    showBtn: { type: Boolean, default: true },
    buyDate: { type: String, required: false },
    disabled: { type: Boolean, default: false }
  },
  mixins: [productMixin],
  data() {
    return {
      cardImageHeight: "170px"
    };
  },
  computed: {
    ...mapGetters({
      createOfferService: "custom/getCreateOfferService"
    }),
    cardContentHeight() {
      return `calc(100% - ${this.cardImageHeight})`;
    }
  },
  methods: {
    async addPersonalPromoItem(product) {
      this.$emit("addPersonalPromoItem", product);
      if (this.createOfferService == "online") {
        // if online, ask if want also add product to cart
        let res = await this.$dialog.confirm({
          text: global.vm.$t("createYourOffer.addToCart")
        });
        if (res) {
          this.addToCart(this.quantity, {
            infos: {}
          });
        }
      }
    }
  }
};
</script>
