<template>
  <v-card class="promo-item-selected px-5 py-2 mb-3">
    <div class="d-flex flex- flex-lg-row align-center">
      <img
        class="promo-item-selected-img mr-2"
        :src="product.mediaURL"
        :alt="`${product.name}`"
      />
      <div class="card-content w-100">
        <p
          v-if="item && createOfferService == 'online'"
          class="mb-0 text-right primary--text font-weight-bold"
        >
          {{ $t("createYourOffer.promoInCart") }}
        </p>
        <v-card-title class="px-0 text-body-2 font-weight-bold">{{
          product.name
        }}</v-card-title>
        <v-card-subtitle class="px-0 pb-0">
          {{ product.shortDescr }}
        </v-card-subtitle>
        <small>{{ product.codInt /* + "-" + product.codVar */ }}</small>
        <div class="d-flex justify-space-between align-center w-100">
          <!--DISCOUNT BADGE-->
          <div>
            <span
              v-if="product.warehousePromo"
              class="text-uppercase rounded-sm py-1 px-3 promo1 white--text font-weight-bold d-block"
              v-html="product.warehousePromo?.view?.header"
            ></span>
            <small>
              {{ $t("createYourOffer.promoMaxQuantity") }}
            </small>
          </div>
          <v-card-actions>
            <v-btn
              v-if="!item && createOfferService == 'online'"
              icon
              @click="
                addToCart(quantity, {
                  infos: {}
                })
              "
            >
              <v-icon color="primary">$cart</v-icon>
            </v-btn>

            <v-btn
              icon
              @click="
                deleteProduct(product.warehousePromo.view.warehousePromoId)
              "
            >
              <v-icon color="default">$delete</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.promo-item-selected {
  .promo-item-selected-img {
    width: 70px;
    height: 70px;
  }
}
</style>
<script>
// import customService from "@/service/customService";
import productMixin from "~/mixins/product";
import { mapGetters } from "vuex";

export default {
  name: "CreateOfferCard",
  props: {
    product: { type: Object, required: true }
  },
  mixins: [productMixin],
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId",
      createOfferService: "custom/getCreateOfferService"
    }),
    item() {
      return this.getItemByProductId(this.product.productId);
    }
  },
  methods: {
    deleteProduct(wpId) {
      this.$emit("removePersonalPromoItem", wpId);
      // customService.removeItemFromPromoList(
      //   this.product.productId,
      //   this.item.itemId
      // );
    },
    async addToCart(quantity, params, searchParams) {
      if (global.config.anonymousCart || (await this.needLogin("addtocart"))) {
        if (this.giftCode) {
          await this.sendGiftCode(this.giftCode);
        }
        //check if there are options to choose
        this.checkSelectOptionAndAdd(quantity, params, searchParams);
      }
    }
  }
};
</script>
